@use "@angular/material" as mat;
@import "/src/styles/typography.scss";
@import "/src/styles/variables.scss";

.button {
  // common button
  border: none;
  height: 42px;
  padding: 8px 24px;
  border-radius: 4px !important;
  font-style: normal;
  font-family: $claas-default-font-family;
  font-weight: $claas-bold-default-font-weight;
  font-size: 14px;
  line-height: 24px;
  width: max-content;

  &.small {
    padding: 4px 10px;
    height: 30px;
    font-size: 13px;
    line-height: 22px;
  }

  &.medium {
    padding: 6px 16px;
    height: 36px;
    font-size: 14px;
    line-height: 24px;
  }

  &.large {
    height: 56px;
    font-size: 16px;
    line-height: 26px;
  }

  &.with-icon {
    lib-icon {
      margin-right: 8px;
    }
  }

  &.button-contained {
    background: $primary-color;
    color: $color-primary-contrast;
    &:hover,
    &.hover {
      color: $color-primary-contrast;
      cursor: pointer;
      background: $color-button-hover-backgrond;
    }
  }

  &.button-outline {
    border: 1px solid $primary-color;
    color: $primary-color;
    background: $color-background;

    &:hover,
    &.hover {
      color: $primary-color;
      cursor: pointer;
      background: $color-claas-green-hover;
    }

    &.button-error {
      border: 1px solid $error-color;
      color: $error-color;
      background: $color-background;

      &:hover,
      &.hover {
        color: $error-color;
        cursor: pointer;
        background: rgb(156, 34, 43, 0.08);
      }
    }

    &.black-label {
      color: $black_87;
      font-weight: $claas-bold-default-font-weight;
    }

    &.uncolored {
      border: 1px solid $color-grey_50;
      color: $black_87;

      &.light {
        border: 1px solid $color-grey_84_50;
        color: $color-grey_84;
      }
    }

    &.disabled, &:disabled {
      border: 1px solid $black_12;
      color: $black_30;
      pointer-events: none;
      background: $color-background;
    }

    &.transparent {
      background: transparent;
    }
  }

  &.button-text {
    border: none;
    color: $primary-color;
    background: $color-background;

    &:hover {
      background: $color-claas-green-hover;
      cursor: pointer;
    }

    &.secondary {
      color: $color-grey3;
      background-color: transparent;

      &.with-border {
        color: $color-grey_84;
        border: 1px solid $color-grey_84_50;
      }

      &:hover,
      &.hover {
        cursor: pointer;
        color: $color-black-4;
        background-color: $black_06;
      }
    }

    &.link {
      color: $color-grey_84;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        color: $color-black-4;
        background-color: $black_06;
      }

      &.dark-primary {
        color: $color-button-dark-green;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &.gray {
    background-color: $color-background-alt-light;
    color: $color-background-alt-dark;
    &.button-outline {
      border: 1px solid $color-background-alt-dark;
    }
    &:hover,
    &.hover {
      cursor: pointer;
      background-color: #ddd;
      color: $color-background-alt-dark;
    }
  }

  &.error {
    background-color: $color-error;
    color: $color-background;

    &:hover,
    &.hover {
      cursor: pointer;
      background-color: $color-error-dark;
      color: $color-background;
    }
  }

  &.main {
    height: 36px;
    height: 24px;
    padding: 6px 16px;
    box-sizing: content-box;
    font-style: normal;
    font-weight: $claas-bold-default-font-weight;
  }

  &.disabled, &:disabled {
    background-color: $black_12;
    color: $black_30;

    &:hover {
      cursor: default;
      background-color: $black_12;
      color: $black_30;
    }
  }

  &.icon {
    background: transparent;
    border-radius: 4px;
    color: $color-grey_84;

    &:hover {
      background: $color-grey_84_08;
      cursor: pointer;
    }
  }
}

.button-icon {
  border-radius: 50%;
  background-color: transparent;
  border: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:hover {
    background-color: $black_06;
  }

  &.disabled, &:disabled {
    color: $color-disabled;
    pointer-events: none;
    background: transparent;

    lib-icon .mat-icon {
      color: $color-disabled;
      pointer-events: none;
      background: transparent;
    }

    &:hover {
      pointer-events: none;
    }
  }
}

.cde-btn {
  width: max-content;
  padding: 6px 16px;
  box-sizing: content-box;
  font-style: normal;
  font-family: $claas-default-font-family;
  font-weight: $claas-bold-default-font-weight;
  font-size: 14px;
  line-height: 24px;
  color: $black_87;
  letter-spacing: 0.4px;
  height: 36px;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  ::ng-deep .mat-button-wrapper {
    height: 24px;
    display: flex;
    align-items: center;
  }
  .cde-btn-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
  &.with-icon {
    .cde-btn-text {
      margin-left: 8px;
    }
  }

  &.white {
    .cde-btn-text {
      color: $color-grey3 !important;
    }
  }

  &.mat-primary.mat-mdc-unelevated-button {
    &:hover,
    &.hover {
      color: $color-primary-contrast;
      cursor: pointer;
      background: $color-button-hover-backgrond;
    }
  }
}
