@use '@angular/material' as mat;
@import '/src/styles/typography.scss';
@import '/src/styles/base.scss';
@import '/src/styles/variables.scss';

.mat-mdc-icon-button {
  width: 48px;
  height: 48px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background-color: $black_06;
  }
  &.mat-mdc-button-base {
    width: 40px;
    height: 40px;
    padding: 0;
  }
}

.mat-mdc-card {
  background: $color-background;
}
.mat-mdc-card:not([class*='mat-elevation-z']) {
  box-shadow: 6px 10px 21px $black_01;
}

// .mat-mdc-card-content {
//   padding: 0 !important;
// }

.weather-icon-box {
  width: 48px;
  height: 50px;
  box-sizing: border-box;
  display: inline-block;
}

.pure-card {
  box-shadow: none !important;
  border: none !important;
}

app-preferred-dealer-view {
  /*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  mat-card.shop-card {
    box-shadow: none !important;
    border: none !important;
  }
}

input.mat-mdc-input-element {
  font-size: 16px;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-label-wrapper {
  top: -1em;
}

.mat-mdc-form-field.mat-focused {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field-ripple {
    background-color: $color-claas-green;
  }
}

.mat-mdc-form-field-focus-overlay {
  background-color: white;
}

/*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: $black;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $color-claas-green;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-focused .mat-form-field-label {
  color: $color-claas-green;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: unset;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-toolbar .mat-form-field-underline {
  display: none !important;
}

.mat-mdc-list-base {
  padding-top: 9px;
}

.mat-mdc-menu-panel {
  max-width: none !important;
  min-width: none !important;
}

.mat-drawer-inner-container {
  overflow: hidden;
}

.mat-calendar-body-selected {
  background-color: $color-claas-green;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: $black_06;
}

.mat-mdc-input-element {
  line-height: 24px;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding-top: 9px;
}

.mat-mdc-select-arrow-wrapper {
  vertical-align: baseline;
}

.cde-custom-select {
  position: relative;
  height: 40px;
  border: 1px solid $black_12;
  border-radius: 4px;
  .mat-mdc-select {
    display: inline-block;
    width: 100%;
    outline: none;
    height: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    &.mat-select-disabled {
      opacity: 0.95;
      pointer-events: none;
    }
    .mat-mdc-select-trigger {
      width: 100%;
      height: 100%;
      line-height: 40px;
    }

    .mat-mdc-select-min-line {
      font-size: 14px;
      line-height: 100%;
      padding-left: 10px;
      height: calc(100% - 2px);
      display: inline-block;
    }
  }
}

.user-menu,
.mat-mdc-menu-content {
  button span {
    color: $black_58;
  }
}

.organisation-selector {
  .organisation-selector-menu-icon {
    height: 24px;
    width: 24px;

    mat-icon {
      display: block;
    }

    img {
      width: 100%;
    }
  }
}
.ml-7 {
  margin-left: 7px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-28 {
  margin-bottom: 28px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-5 {
  margin-top: 5px;
}
.db {
  display: block;
}

.mat-sidenav-content {
  overflow: hidden;
}
.custom-list-item {
  text-decoration: none;
  &:hover {
    .cde-mat-nav-icon {
      color: #676767 !important;
    }
  }
  /*********************************/
  /**** hide focus state temporary
  /*********************************/
  // &:active,
  // &:focus {
  //   .cde-mat-nav-icon {
  //     color: #676767 !important;
  //   }
  // }
  &.active {
    .cde-mat-nav-icon {
      color: $black_58 !important;
    }
  }
}

.dropdown-options-below {
  margin-top: 35px;
}

.mat-mdc-menu-content .mat-mdc-menu-item:has(.material-icons, mat-icon, [matButtonIcon]) {
  padding: 6px 40px 6px 16px;
}

.mat-mdc-menu-content .mat-mdc-menu-item {
  padding: 6px 40px 6px 16px;
  min-height: unset;
  &:hover {
    background: $color-claas-green-hover;
  }
  span {
    font-size: initial;
  }

  .mat-mdc-menu-item-text,
  .mat-mdc-menu-item-text span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.15px;

    color: $black_87 !important;
    // @include mat.typography-level($fontConfig, 'subtitle-2');
    line-height: 20px !important;
    font-size: 14px !important;
  }
}

.mat-badge-content {
  background: $color-claas-green;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -3px;
}

.mat-mdc-dialog-container {
  min-height: 176px !important;
  &.mdc-dialog .mat-mdc-dialog-content {
    color: $black_87;
    padding: 24px;
  }
}

.config-dialog {
  .mat-mdc-dialog-container {
    overflow: hidden !important;
  }
}

// increase specifity
.mat-typography {
  .mat-mdc-form-field,
  .mat-mdc-floating-label {
    @include mat.m2-typography-level($fontConfig, 'body-2');
  }
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background-color: unset !important;
  height: 52px;
  max-height: 52px;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: unset;
}

.select-shop-dialog .mat-mdc-dialog-container {
  height: auto;
}

.mdc-button__label {
  display: flex;
  align-items: center;
}

//
// ************** COPIED FROM CDE-THEME.SCSS ******************
//

// Handle buttons appropriately, with respect to line-height
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 1.15em;
  // margin: 0 0.65em;
  min-width: 3em;
  line-height: 62.400000000000006px;
  &:active .mat-mdc-button-persistent-ripple::before,
  &:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 0;
  }
}

.mat-mdc-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  height: 0;
  background-color: transparent !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-ripple {
  background-color: transparent;
}

.mat-calendar-body-cell.highlighted-date {
  background: rgba(178, 198, 24, 0.24) !important;
  border-radius: 100% !important;
}

.mat-calendar-body-cell.highlighted-preselected-date {
  .mat-calendar-body-cell-content {
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: unset;
      bottom: 3px;
      left: 0;
      right: 0;
      background: $color-claas-green !important;
      border-radius: 100% !important;
      width: 8px;
      height: 8px;
      margin: auto;
      z-index: -1;
    }
    &.mat-calendar-body-selected {
      &:before {
        content: '';
        background: $color-white !important;
      }
    }
  }
  .mat-calendar-body-today {
    &.mat-calendar-body-cell-content {
      &:before {
        content: '';
        background: $color-claas-green !important;
      }
    }
  }
  &:hover,
  &.mat-calendar-body-range-start,
  &.mat-calendar-body-range-end {
    .mat-calendar-body-cell-content {
      &:before {
        content: '';
        background: $color-white !important;
        z-index: 99999;
      }
    }
  }
}

.tooltip {
  font-family: $claas-default-font-family;
  font-style: normal;
  font-size: 11px;
  line-height: 14px;
  color: $dark-secondary-text;
}

mat-paginator {
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__leading {
    border: none !important;
  }

  .mat-mdc-select-value {
    margin-right: 10px;
  }
}

.mat-mdc-table {
  font-size: mat.m2-font-size($fontConfig, body-1);
  font-family: mat.m2-font-family($fontConfig, body-1);

  &.table {
    width: 100%;

    td.number-column {
      text-align: right;
    }

    th.number-column .mat-sort-header-container {
      justify-content: flex-end;
    }

    tr {
      background-color: $color-background;
    }

    th {
      @include subtitle2-text();
      background-color: $color-background;
      color: $black_58;
      padding: 16px;

      &.active-sort {
        color: $black_87;
      }
    }

    td {
      @include mat.m2-typography-level($fontConfig, 'subtitle-2');
      color: $black_87;
      padding: 16px;
    }
  }
}

.mat-mdc-paginator {
  &.table-paginator {
    .mat-mdc-paginator-page-size-label {
      min-width: fit-content;
      margin-right: 8px;
      color: $dark-primary-text;

      @include mat.m2-typography-level($fontConfig, 'caption');
    }
    .mat-mdc-form-field-infix {
      width: auto;

      .mat-mdc-select {
        width: auto;
        /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        .mat-mdc-select-value {
          max-width: fit-content;
          /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          .mat-select-value-text {
            font-family: $claas-default-font-family;
            color: $dark-primary-text;
            margin-right: 6px;
          }
        }
      }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      height: 0;
    }

    .mat-mdc-paginator-range-label {
      font-family: $claas-default-font-family;
      color: $dark-primary-text;
    }
  }
}

.disabled-tab-group {
  &.mat-mdc-tab-group {
    &.mat-primary .mat-ink-bar {
      background-color: $black_30;
    }
  }
}

.mat-mdc-select-panel {
  &.add-product-select-panel {
    overflow: auto !important;
    margin-top: 6px !important;
  }
}

.custom-panel {
  max-height: 217px;
  overflow: auto;
  .hide-mat-checkbox {
    .mat-pseudo-checkbox {
      display: none !important;
    }
  }
}

html {
  @include cde-scrollbar-large;
}
body {
  .cde-scrollbar {
    @include cde-scrollbar;
  }
}

.cde-autocomplete-overlay {
  .mat-mdc-autocomplete-panel {
    @include cde-scrollbar;
  }
}

.overview-container {
  .atlas-map-canvas {
    height: 100% !important;
  }
}

.cde-mat-autocomplete {
  input.cde-input {
    padding-right: 28px;
    box-sizing: border-box;
  }
}

.header-round-icon {
  .mat-badge-hidden .mat-badge-content:empty {
    display: inline-block;
  }
}

.cde-select-panel {
  min-width: 100% !important;
  margin-top: 3px !important;
  ::ng-deep .cdk-overlay-container {
    left: 30vw;
  }
  ::ng-deep .cdk-overlay-pane {
    left: 0 !important;
    transform: none !important;
  }
  ::ng-deep .mat-mdc-select-panel {
    left: 0;
  }
}

.cde-select-tag {
  .mat-mdc-select-placeholder {
    font-size: 14px !important;
  }
}

.mat-mdc-select-panel .mat-mdc-option {
  @include mat.m2-typography-level($fontConfig, 'body-2');
}

.mdc-notched-outline__notch {
  border-right: none !important;
}

.cde-mat-autocomplete {
  .mat-mdc-option {
    font-size: 14px;
  }
}

.cde-custom-select {
  position: relative;
  .cdk-overlay-container {
    left: 30vw;
  }
  .cdk-overlay-pane {
    left: 0 !important;
    transform: none !important;
  }
  .mat-mdc-select-panel {
    left: 0;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-mdc-select-value {
    max-width: 100%;
  }
}

.cde-select-overlay {
  &.cdk-overlay-pane {
    transform: translateX(0) !important;
    margin-top: 36px;
  }
  &.cde-filter-dropdown {
    left: 0 !important;
  }
}

.cde-material-select-overlay {
  &.cdk-overlay-pane {
    transform: translateX(0) !important;
    margin-top: 34px;
  }
  .cde-filter-dropdown {
    left: -11px !important;
  }
}

.cde-filter-dropdown {
  &.cdk-overlay-pane {
    margin-top: 4px;
    min-width: fit-content;
  }
}
// Custom styles for MOZILLA FIREFOX browser
@-moz-document url-prefix() {
  .cde-filter-dropdown {
    &.cdk-overlay-pane {
      margin-top: 4px;
    }
  }
  .filter-cancel-button {
    .count-cancel-button-label {
      padding-bottom: 1px;
    }
    .count-cancel-button-icon {
      padding-bottom: 1px;
    }
  }
  .filter-button {
    &.is-active {
      /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-value-text,
      .mat-mdc-select-placeholder {
        margin-top: 5px !important;
      }
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.no-multiselect-backdrop + * > .cde-filter-dropdown.cde-filter-dropdown {
  left: 14px;
}

// overriding panel class for Operating instructions search field
.pc + * > .cdk-overlay-pane {
  width: auto;
  height: auto;
}

.pc-max + * > .cdk-overlay-pane {
  width: auto;
  height: auto;
  margin-top: 4px;
  transform: translateX(-49px);
  .mat-mdc-menu-panel {
    max-width: 1200px !important;
    max-height: 270px;
    box-shadow: none;
    border: 1px solid $color-light-gray;

    .mat-mdc-menu-content {
      padding: 0;
    }
  }
}

.pc3 + * > .cdk-overlay-pane {
  transform: translate(100%, -44px) !important;
  width: auto;
  height: auto;

  .mat-mdc-menu-panel {
    max-width: max-content !important;
  }
}

.notifications + * > .cdk-overlay-connected-position-bounding-box {
  top: 56px !important;
  right: 0 !important;
  width: 400px;
  height: calc(100vh - 56px);

  .mat-mdc-menu-content {
    padding: 0;
  }
}

.actions-menu-backdrop + * > .cdk-overlay-pane {
  transform: translateX(-9px);
  .mat-mdc-menu-panel {
    min-width: 125px;
  }
  .actions-menu-item,
  .mdc-list-item__primary-text {
    display: block;
    line-height: 32px !important;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: $black_87;
  }
}
.mat-mdc-icon-button.mat-mdc-button-base.actions-menu-toggle-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}
.actions-menu-backdrop + * > .cdk-overlay-pane {
  .mat-mdc-menu-panel {
    min-width: 125px;
  }
}

.edit-notes-dropdown + * > .cdk-overlay-pane {
  .mat-mdc-menu-panel {
    min-height: 85px;
  }
  .actions-menu-item {
    display: block;
    height: 32px;
    line-height: 32px !important;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: right;
    color: $black_87;
  }
}

.transparent-btn {
  height: 36px !important;
  border: 1px solid $color-claas-green !important;
  border-radius: 4px;
  font-weight: $claas-bold-default-font-weight;
  font-size: 14px;
  line-height: 24px !important;
  letter-spacing: 0.4px;
  color: $black_87;
  text-align: center !important;
}

.machine-tile .mat-mdc-select-value {
  text-align: right;
}

.machine-tile .mat-mdc-select-value,
.machine-tile mat-select.sortDropdown,
.machine-tile .sortDropdown,
.machine-tile div,
.machine-tile .mat-mdc-select-arrow-wrapper {
  line-height: 18px !important;
}

.grid-list-wrapper {
  .mat-grid-tile {
    overflow: visible;
  }
}

.sort-action-menu {
  .mat-mdc-select-panel {
    width: 115px !important;
    min-width: 111px !important;
  }

  .mat-mdc-select-value {
    text-align: center;
    padding-left: 23px;
  }
  .mat-mdc-select-min-line {
    display: inline-block;
  }
  .mdc-list-item__primary-text {
    font-style: normal;
    font-size: 14px;
    text-align: right;
    line-height: 20px !important;
    color: $black_87;
    height: auto;
  }
}

.machines-overview-panel {
  width: unset !important;

  .mat-mdc-select-panel {
    mat-option.telemetry-label {
      font-size: 14px !important;
      line-height: 20px !important;
      color: $black_87;
      min-height: unset;
      padding: 6px 40px 6px 16px;
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-focus-overlay {
  background-color: transparent !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
::ng-deep .mat-button-focus-overlay {
  display: none;
}

.font-disabled-lubricants {
  color: $black_30 !important;
}

.mat-mdc-tooltip {
  width: max-content;
}
.white-tooltip {
  background: $color-white;
  box-shadow: 0px 5px 5px -3px $black_02, 0px 8px 10px 1px $black_14, 0px 3px 14px 2px $black_12;
  color: $black_87 !important;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  margin: -12px 0 0 20px !important;
}
.mt-tooltip {
  margin-top: 2px !important;
  margin-left: 12px !important;
}
.add-product-modal {
  position: relative;
  .mat-step-header,
  .mat-horizontal-content-container {
    padding: 0 !important;
  }
  .mat-horizontal-stepper-header-container {
    .mat-stepper-horizontal-line {
      margin: 0 12px;
    }
  }
}
mat-stepper,
mat-horizontal-stepper {
  .mat-horizontal-content-container {
    padding: 0 !important;
  }

  .mat-horizontal-stepper-header-container {
    .mat-step-header {
      .mat-step-icon {
        background-color: $black_12;
        color: $black_58;

        &.mat-step-icon-selected {
          color: $black_87;
          background-color: $primary-color;
        }

        &.mat-step-icon-state-edit {
          background-color: $black_12;
          color: $black_58;
        }

        .mat-step-icon-content {
          @include mat.m2-typography-level($fontConfig, 'caption');
        }
      }

      .mat-step-label {
        color: $black_58;
        @include mat.m2-typography-level($fontConfig, 'body-2');

        &.mat-step-label-selected {
          color: $black_87;
          @include subtitle2-text();
        }
      }

      background-color: transparent;
      padding-left: 0px;
      padding-right: 0px;
      height: 80px;
    }

    .mat-step-header:hover {
      background: none;
    }

    .mat-stepper-horizontal-line {
      margin: 0 30px;
    }

    .mat-stepper-horizontal-line {
      margin: 0 8px;
    }
  }

  .mat-step-label {
    &:not(.mat-step-label-selected) {
      display: none;
    }
  }

  .mat-horizontal-stepper-header-container .mat-step-header .mat-step-icon.mat-step-icon-selected {
    color: rgba(0, 0, 0, 0.87);
    background-color: #b3c618;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
mat-radio-group {
  .mat-mdc-radio-button ~ .mat-radio-button {
    margin-left: 16px;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $color-claas-green;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $color-claas-green;
  }

  margin-bottom: 16px;
  margin-left: 10px;
}

.map-optimize-wrapper {
  #map-optimize {
    height: calc(100vh - 400px);
  }
}

.go-home-btn {
  width: max-content;
  height: 36px;
  padding: 6px 16px;
  box-sizing: content-box;
  font-style: normal;
  font-weight: $claas-bold-default-font-weight;
  font-size: 14px;
  line-height: 36px;
  color: $black_87;
  letter-spacing: 0.4px;
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper {
    height: 36px;
    display: flex;
    align-items: center;
  }

  .go-home-btn-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  .go-home-btn-text {
    margin-left: 8px;
  }
}

// .tile-menu {
//   .mvm-button {
//     height: 36px;
//     __<<ngM2ThemingMigrationEscapedComment29>>__
//     .mat-button-wrapper {
//       display: flex;
//       line-height: 20px;
//     }
//   }
// }

.mat-mdc-table {
  .mdc-data-table__row {
    height: 62px;
    &:last-child .mdc-data-table__cell {
      border-bottom: 1px solid $black_12;
    }
  }

  .cdk-keyboard-focused {
    .mat-sort-header-container {
      outline: none;
      border: none !important;
    }
  }
  .mat-sort-header-container {
    outline: none;
    border: none !important;
    /*********************************/
    /**** hide focus state temporary
    /*********************************/
    // &:active,
    // &:focus,
    // &:focus-visible,
    // &:focus-within {
    //   outline: none;
    //   border: none;
    // }
    .mat-sort-header-arrow {
      color: $black_87;
    }
    .mat-sort-header-content {
      outline: none;
      border: none;
      /*********************************/
      /**** hide focus state temporary
    /*********************************/
      // &:active,
      // &:focus,
      // &:focus-visible,
      // &:focus-within {
      //   outline: none;
      //   border: none;
      // }
    }
  }
}

.cde-btn.cde-delete-btn {
  background-color: $color-error-main;
  color: $color-white;
  .cde-btn-text {
    color: $color-white;
  }
}

.cde-arrow {
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
}

.cde-accordion-item {
  &.is-closed {
    min-height: 36px;
  }
  &.is-open {
    .equipment-description {
      min-height: 265px;
      padding-bottom: 18px;
    }
  }
}

.toggle-label {
  color: $black_58;
  font-style: normal;
  font-size: 10.5px;
  line-height: 12px;
  position: absolute;
  top: -4px;
  left: 11px;
}
.slide-toggle-status {
  display: inline-block;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $black_87;
  margin-left: 12px;
}

.cde-menu-trigger {
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  outline: 1px solid transparent;
}

.dib {
  display: inline-block;
}
// keep outside the form styling as it is placed in an angular overlay container
/* ::ng-deep */
.mat-mdc-select-panel.select-menu-panel {
  overflow: auto !important; // TODO: material overrides define overflow to be hidden?
}

.mat-mdc-form-field-flex {
  align-self: center;
}

.mat-form-field-appearance-standard .mat-mdc-form-field-flex {
  padding-top: 0;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker .mat-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
  width: 264px !important;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker.mat-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
  width: 264px !important;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-standard .mat-mdc-form-field-flex {
  padding-top: 0;
}
.default-rangepicker .mat-date-range-input-container {
  width: 90%;
  padding-left: 6px;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}

.default-rangepicker .mat-mdc-form-field-flex {
  align-items: center;
  height: 52px;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker .mat-form-field-appearance-fill .mat-mdc-form-field-flex {
  border-radius: 4px;
  padding: 0;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker .mat-form-field-appearance-legacy .mat-mdc-form-field-infix {
  padding: 0;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker.mat-form-field-appearance-standard .mat-mdc-form-field-flex {
  padding-top: 0;
}

.default-rangepicker .mat-date-range-input {
  display: block;
  width: 100%;
  height: 50px;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 0px;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker .mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle .mat-mdc-icon-button,
.default-rangepicker .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle .mat-mdc-icon-button,
.default-rangepicker .mat-form-field-appearance-legacy .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle .mat-mdc-icon-button {
  font-size: inherit;
  width: 26px;
  height: 26px;
  padding: 0;
  border-radius: 0;
  height: 50px;
  width: 30px;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}
.default-rangepicker.mat-mdc-form-field-type-mat-date-range-input
  .mat-mdc-form-field-icon-suffix
  .mat-datepicker-toggle
  .mat-mdc-button-base,
.default-rangepicker.mat-form-field-appearance-standard .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle .mat-mdc-button-base {
  font-size: inherit;
  width: 26px;
  height: 26px;
  padding: 0;
  border-radius: 0;
  height: 50px;
  width: 30px;
  line-height: 63px;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon,
.default-rangepicker .mat-form-field-appearance-legacy .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle-default-icon {
  width: 24px;
}
.default-rangepicker .mat-date-range-input-wrapper {
  position: relative;
  height: 50px;
  width: 46%;
  font-size: 14px;
  input.mat-date-range-input-inner {
    box-sizing: border-box;
    text-align: center;
  }
}
.default-rangepicker .mat-mdc-icon-button .mat-mdc-button-touch-target {
  left: calc(50% - 5px);
  width: 40px;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker.mat-form-field-appearance-standard .mat-mdc-form-field-flex {
  padding-top: 0;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker .mat-mdc-form-field-infix {
  padding: 0;
  border-top: none;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker .mat-form-field-appearance-legacy .mat-form-field-label,
.default-rangepicker.mat-form-field-appearance-standard .mat-form-field-label {
  top: 23px;
  left: 12px;
  background: $color-background;
  width: auto !important;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: $black_67;
  padding-left: 4px;
  padding-right: 4px;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: transparent;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.default-rangepicker .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0;
}
.default-rangepicker .mat-mdc-form-field.mat-focused .mat-form-field-ripple,
.default-rangepicker.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: transparent;
}
.default-rangepicker .mat-datepicker-toggle-active {
  color: rgba(0, 0, 0, 0.54);
}
.default-rangepicker .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: 0;
}
.marker-organization-popup {
  margin: 16px;
  .marker-organization-popup-address {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
  }
}

.marker-dealer-popup {
  margin: 16px;
  .marker-dealer-popup-address {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
  }
  .marker-dealer-popup-contacts {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
  }
}

.marker-user-popup {
  margin: 16px;
  .marker-user-popup-address {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
  }
}

.marker-popup-content {
  width: 370px;
  height: 286px;
}

@media screen and (max-width: 640px) {
  .cde-accordion-item {
    &.is-open {
      .equipment-description {
        min-height: 262px;
        padding-bottom: 4px;
      }
    }
  }
}
