/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48I2IzYzYxOCIsIj9lcjwjY2JkYTdjIiwiO2VyPCM3Nzg0MTB$LCIlPmBePCM2YjZiNmIiLCI~ZXI8I2M3YzdjNyIsIjtlcjwjNDQ0NDQ0fiwid2Fybj5gXjwjOWUyOTIzIiwiP2VyPCNmODgwNzgiLCI7ZXI8I2UzMWIwY34sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZSIsImxpbmVIZWlnaHQ$NDAsInNpemU$MzIsInNwYWNpbmc$MC4yNX0sYEA8dGl0bGUiLCJ2YXJpYW50PHJlZ3VsYXIiLCJsaW5lSGVpZ2h0PjM2LCJzaXplPjI4LCJzcGFjaW5nPjAuMjV9LGBAPHN1YiktMiIsImxpbmVIZWlnaHQ$MzIsInNpemU$MjQsInNwYWNpbmc$MH0sYEA8c3ViKS0xIiwidmFyaWFudDxyZWd1bGFyIiwibGluZUhlaWdodD4zMiwic2l6ZT4yMCwic3BhY2luZz4wfSxgQDxib2R5LTIiLCJ2YXJpYW50PHJlZ3VsYXIiLCJsaW5lSGVpZ2h0PjIwLCJzcGFjaW5nPjAuMTV9LGBAPGJvZHktMSIsImxpbmVIZWlnaHQ$MjQsInNpemU$MTYsInNwYWNpbmc$MC4xNX0sYEA8YnV0dG9uIiwibGluZUhlaWdodD4yNCwic3BhY2luZz4wLjR9LGBAPGNhcHRpb25$LGBAPGlucHV0IiwibGluZUhlaWdodD4xMiwic2l6ZT4xMiwic3BhY2luZz4wLjE1fV0sImljb25zPEZpbGxlZCIsIj9uZXNzPnRydWUsInZlcnNpb24$MTJ9
*/

@use 'sass:map';
@use '@angular/material' as mat;

@use '@material/switch/switch' as mdc-switch;
@use '@material/switch/switch-theme' as mdc-switch-theme;

@import './typography.scss';
@import './variables.scss';

// @include mat.core();

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Foreground Elements
$mat-light-theme-foreground: (
  base: $black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: $black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: $color-white-gray;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// mdc-filled-button-label-text-color

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba($color-white-gray, 0.04);
$light-bg-alpha-12: rgba($color-white-gray, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($fontConfig);`
@include mat.core();

// Theme Config
$primary-color: $color-claas-green;

body {
  --primary-color: $color-claas-green;
  --primary-lighter-color: #cbda7c;
  --primary-darker-color: #778410;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
  --mdc-filled-button-label-text-color: #{$color-button-green};
}
// $mat-primary: (
//   main: $color-claas-green,
//   lighter: #cbda7c,
//   darker: #778410,
//   200: $color-claas-green,
//   // For slide toggle,
//   contrast:
//     (
//       main: $dark-primary-text,
//       lighter: $dark-primary-text,
//       darker: $light-primary-text,
//     ),
// );
// $theme-legacy-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: $color-grey3;
  --accent-lighter-color: $color-black-4;
  --accent-darker-color: $color-black-4;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
// $mat-accent: (
//   main: $color-grey3,
//   lighter: $color-black-4,
//   darker: $color-black-4,
//   200: $color-grey3,
//   // For slide toggle,
//   contrast:
//     (
//       main: $light-primary-text,
//       lighter: $dark-primary-text,
//       darker: $light-primary-text,
//     ),
// );
// $theme-legacy-accent: mat.define-palette($mat-accent, main, lighter, darker);

$error-color: #f44336;

body {
  --warn-color: #f44336;
  --warn-lighter-color: #f88078;
  --warn-darker-color: #e31b0c;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
// $mat-warn: (
//   main: #f44336,
//   lighter: #f88078,
//   darker: #e31b0c,
//   200: #f44336,
//   // For slide toggle,
//   contrast:
//     (
//       main: $light-primary-text,
//       lighter: $dark-primary-text,
//       darker: $light-primary-text,
//     ),
// );
// $theme-legacy-warn: mat.define-palette($mat-warn, main, lighter, darker);
// $theme-legacy: (
//   primary: $theme-legacy-primary,
//   accent: $theme-legacy-accent,
//   warn: $theme-legacy-warn,
//   is-dark: false,
//   foreground: $mat-light-theme-foreground,
//   background: $mat-light-theme-background,
// );

// $altTheme: (
//   primary: $theme-legacy-primary,
//   accent: $theme-legacy-accent,
//   warn: $theme-legacy-warn,
//   is-dark: true,
//   foreground: $mat-dark-theme-foreground,
//   background: $mat-dark-theme-background,
// );

$class-primary: (
  50: #e2e4cf,
  100: #d9deac,
  200: #ced885,
  300: #c3d260,
  400: #b9ca3e,
  500: #b3c618,
  600: #a5b412,
  700: #979f05,
  800: #868902,
  900: #6a6200,
  A100: #dbe86a,
  A200: #d2e91f,
  A400: #a9e800,
  A700: #94d400,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$claas-error: (
  50: #f88078,
  100: #f88078,
  200: #f88078,
  300: #f88078,
  400: #f44336,
  500: #f44336,
  600: #f44336,
  700: #e31b0c,
  800: #e31b0c,
  900: #e31b0c,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$theme-class-primary: mat.m2-define-palette($class-primary);
$theme-class-accent: mat.m2-define-palette($class-primary, A200, A100, A400, A700);
$theme-class-error: mat.m2-define-palette($claas-error);

// $theme: (
//   primary: $theme-class-primary,
//   accent: $theme-class-accent,
//   warn: $theme-class-error,
//   is-dark: false,
//   foreground: $mat-light-theme-foreground,
//   background: $mat-light-theme-background,
// );

$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-class-primary,
      accent: $theme-class-accent,
      warn: $theme-class-error,
    ),
    typography: $fontConfig,
  )
);

@include mat.typography-hierarchy($fontConfig);

// @include mat.all-component-typographies($fontConfig);
// Theme Init
@include mat.all-component-themes($theme);

// Override handle color when it have a state
.mat-mdc-slide-toggle {
  .mdc-switch {
    @include mdc-switch-theme.theme(
      (
        selected-focus-handle-color: #a5b412,
        selected-hover-handle-color: #a5b412,
        selected-pressed-handle-color: #a5b412,
      )
    );
  }
}
