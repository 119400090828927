@import '/src/styles/typography.scss';
@import '/src/styles/variables.scss';

.mat-datepicker-popup {
  width: auto !important;
  height: auto !important;
  margin-top: 4px;
  margin-left: 1px;
}

.mat-datepicker-toggle-active {
  color: $color-claas-green;
}

.range-picker-wrapper {
  z-index: 99;
  color: $dark-primary-text;
  font-family: $claas-default-font-family;
  &.mat-form-field-appearance-fill {
    .mat-mdc-form-field-infix {
      height: 34px;
      padding: 0 !important;
      border: 1px solid transparent;
      line-height: 32px;
      min-height: unset;
    }
    .mat-mdc-form-field-flex {
      border-radius: 0;
      padding: 0;
    }

    .mat-mdc-text-field-wrapper {
      border-radius: 16px;
    }

    .mat-date-range-input-wrapper {
      height: 28px;
      max-width: 100%;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      .mat-date-range-input-inner {
        height: 28px;
        display: flex;
        cursor: pointer;
        border: none;
        @include pickerInput(14px, 400);
        box-sizing: border-box;
      }
    }
    .mat-date-range-input-wrapper {
      width: 100%;
    }
    .mat-date-range-input-separator {
      width: 11px;
      @include pickerInput(13px, 400);
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.15px;
      margin: 0 0;
    }
    .mat-date-range-input-container {
      padding: 0px 14px;
      background-color: transparent !important;
      border: 2px solid $dark-secondary-text;
      border-radius: 16px;
      &:hover {
        border: 2px solid #616161;
        background-color: $color-background;
      }
      &:active {
        outline: 2px solid $color-claas-green;
        outline-offset: -2px;
        .mat-date-range-input-separator,
        .mat-date-range-input-wrapper {
          .mat-date-range-input-inner {
            @include pickerInputActive(14px, bold);
          }
        }
      }
    }
    &.range-picker-active {
      .mat-date-range-input-wrapper {
        .mat-date-range-input-inner {
          @include pickerInputActive(14px, bold);
        }
      }
      .mat-date-range-input-container {
        @supports (not (-webkit-hyphens: none)) {
          // outline: 2px solid $dark-secondary-text;
          // outline-offset: -2px;
        }

        @supports (-webkit-hyphens: none) {
          &::before {
            content: '';
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            // border: 2px solid $dark-secondary-text;
            border-radius: 16px;
          }
        }
      }

      .mat-date-range-input-separator {
        font-size: 13px;
      }
    }

    .mat-datepicker-toggle .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
      display: none;
    }
  }

  .mat-calendar-body-in-range.mat-calendar-body-range-end .mat-calendar-body-selected {
    background: $color-claas-green;
  }
  .mat-mdc-text-field-wrapper {
    padding: 0;
    height: auto;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  &.mat-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
    cursor: pointer;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-ripple {
    background-color: transparent !important;
  }
  // styles to hide toggle button
  .mat-form-field-suffix,
  .mat-mdc-form-field-icon-suffix {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 32px;
    background-color: transparent;
    border-radius: 16px;
    padding: 0;
    .mat-mdc-icon-button {
      --mat-mdc-button-persistent-ripple-color: #ffff;
      --mat-mdc-button-ripple-color: none;
    }
    .mat-mdc-focus-indicator {
      border-radius: 16px;
    }
    .mdc-icon-button {
      padding: 0 !important;
      width: 100% !important;
      height: 32px !important;
      background: transparent !important;
      .mat-mdc-button-touch-target {
        height: 32px !important;
        border-radius: 16px !important;
        margin-left: 5px;
        &:active,
        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
  .mat-datepicker-toggle-default-icon {
    display: none;
  }
  .mat-button-ripple,
  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-datepicker-close-button {
  display: none !important;
}

.no-date {
  .cde-datepicker-wrapper {
    border: 1px solid $color-error-main;
  }
}
