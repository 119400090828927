// user centric cmp stylings
.uc-embedding-container {
  min-height: 120px;
  overflow: hidden;
  position: absolute; // from doc
  top: 0;
}

.uc-embedding-container:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(51, 51, 51, 0.7);
}

.uc-embedding-container .uc-embedding-wrapper {
  // padding: 36px 27px 10px; // from doc
  padding: 24px;
  z-index: 1;
}

.uc-embedding-container .uc-embedding-wrapper > span {
  display: none;
}

.uc-embedding-container .uc-embedding-wrapper > h3 {
  font-weight: $claas-light-default-font-weight;
  font-size: 24px !important;
  line-height: 26px !important;
  margin-bottom: 10px !important;
  text-align: left;
}

.uc-embedding-container .description-text {
  font-family: "HelveticaNeueW01-45Ligh", arial;
  text-align: left;
}

.uc-embedding-container .not-existing-service {
  font-family: "HelveticaNeueW01-45Ligh", arial;
  text-align: left;
}

.uc-embedding-container .uc-embedding-buttons button {
  font-family: "HelveticaNeueW01-45Ligh", arial;
  font-size: 14px;
  color: #303030;
  background: #f5f5f5;
  transition: background-color 0.2s ease-in-out;
}

.uc-embedding-container .uc-embedding-buttons button:hover {
  background: #dcdcdc;
}

.uc-embedding-container .uc-embedding-buttons button.uc-embedding-accept {
  background: #b3c618;
}

.uc-embedding-container .uc-embedding-buttons button.uc-embedding-accept:hover {
  background: #8a9912;
}
