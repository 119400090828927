@import '/src/styles/variables.scss';

mat-dialog-content.dialog-address-validation {
  h2.mat-mdc-dialog-title {
    padding: 0;

    &::before {
      display: none;
    }
  }

  h5 {
    font-size: 16px;
  }

  .mat-mdc-radio-button {
    p {
      margin-bottom: 0;
    }
  }
}

.mat-mdc-dialog-actions.dialog-address-buttons {
  button {
    width: max-content;
    padding: 6px 16px;
    font-style: normal;
    font-weight: $claas-bold-default-font-weight;
    font-size: 14px;
    letter-spacing: 0.4px;
    height: 36px;
    border-radius: 4px;

    &.dialog-address-cancel-button {
      background: $color-background;
      color: $color-grey3;
      border: 0;
    }

    &.dialog-address-submit-button {
      background: $color-button-green;
      color: $color-primary-contrast;
    }
  }
}
