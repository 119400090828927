// @import url('https://cdn.claas.com/common/fonts/Roboto/complete/roboto.css');

// DEFINE GLOBAL VARIABLES HERE

// COLORS
$color-background: #fff;
$black: #000;
$black_01: rgba(0, 0, 0, 0.01);
$black_02: rgba(0, 0, 0, 0.02);
$black_03: rgba(0, 0, 0, 0.03);
$black_04: rgba(0, 0, 0, 0.04);
$black_06: rgba(0, 0, 0, 0.06);
$black_08: rgba(0, 0, 0, 0.08);
$black_12: rgba(0, 0, 0, 0.12);
$black_14: rgba(0, 0, 0, 0.14);
$black_26: rgba(0, 0, 0, 0.26);
$black_30: rgba(0, 0, 0, 0.3);
$black_42: rgba(0, 0, 0, 0.42);
$black_58: rgba(0, 0, 0, 0.58);
$black_60: rgba(0, 0, 0, 0.6);
$black_67: rgba(0, 0, 0, 0.67);
$black_64: rgba(0, 0, 0, 0.64);
$black_87: rgba(0, 0, 0, 0.87);
$color-grey: rgba(128, 128, 128, 1);
$color-grey2: #696964;
$color-grey3: #6b6b6b;
$color-grey4: #616161;
$color-grey_50: rgba(107, 107, 107, 0.5);
$color-grey_84: rgba(84, 84, 84); // #545454
$color-grey_84_50: rgba(84, 84, 84, 0.5);
$color-grey_84_08: rgba(84, 84, 84, 0.08);
$color-grey_84_04: rgba(84, 84, 84, 0.04);
$color-alt-dark-grey: #696969;
$light-blue: #eaf4fc;
$color-background-inverse: $black;
$color-black-4: #444444;
$color-background-alt-light: #eee;
$color-background-alt-e6: #e6e6e6;
$color-background-alt-ef: #efefef;
$color-background-alt-dark: #646464;
$color-background-4b: #4b4b4b;
$color-background-4c5: #4c550b;
$color-background-snackbar: #323232;
$color-text-snackbar: #621b16;
$color-text-toastr: #663d00;
$color-copy: $color-background-inverse;
$color-copy-inverse: $color-background;
$color-white: $color-background;
$color-white-gray: #fafafa;
$color-copy-fine-print: #999;
$color-button-green: #b3c618;
$color-button-hover-backgrond: #8d9d00;
$color-button-dark-green: #778410;
$color-link: $color-copy;
$color-link-inverse: $color-copy-inverse;
$color-link-dark: #999;
$color-input-error: #aa0b25;
$color-input-placeholder: #696969;
$color-input-background: $color-background-alt-light;
$color-input-focused-background: #f0f4d1;
$color-input-invalid-background: #fce5e5;
$color-input-disabled-background: #fdfdfd;
$color-divider: #d7d7d7;
$color-disabled: #ccc;
$color-form-text: #999999;
$color-claas-green: #b3c618;
$color-dark-claas-green: #6f7e02;
$color-dark-claas-green-hover: #778410;
$color-claas-green-hover2: rgba(179, 198, 24, 0.08);
// this should be the same color as rgba(179, 198, 24, 0.08) but without transparency
$color-claas-green-hover: #f9fbed;
// $color-claas-green-selected: rgba(179, 198, 24, 0.16);   // this color is not tested yet
$color-claas-green-selected: #f3f6da; // this color is not tested yet
$color-light-gray: #e0e0e0;
$color-primary-contrast: #202020;
$color-primary-dark: #535e00;
$color-gray-f5: #f5f5f5;
$color-gray-f7: #f7f7f7;
$color-gray-f4: #f4f4f4;
$color-gray-fdf4: #fdf4e7;
$color-gray-fdec: #fdecea;
$color-gray-d8: #d8d8d8;
$color-gray-e3: #e3e3e3;
$color-gray-c7: #c7c7c7;
$color-gray-e7: #e7e7e7;
$color-gray-eaf: #eaf4fc;
$color-gray-d7: $color-divider;
$color-pending-state: #2196f3;
$color-blue-light: rgba(51, 153, 255, 0.75);
$color-blue-outline: #39f;
$color-blue-outline2: #0b79d0;
// old colors for measurements
// $color-success-main:#407C4C;
// $color-warning-main:#EFCB4B;
// $color-error-main:#9E2923;
// $color-neutral-main: rgba(0,0,0,.3);

// new colors for measurements
$color-success-main: #4caf50;
$color-success-secondary: #47ad2c;
$color-success-spare: #407c4c;
$color-warning-main: #ff9800;
$color-error-main: #f44336;
$color-neutral-main: $black_58;
$color-success: #2e7d32;
$color-success-dark: #1e4620;
$color-success-bg: #c8e6c9;
$color-error: #e31b0c;
$color-error-dark: #c62828;
$color-information: #0d3c61;
$color-information-bg: #bbdefb;
$color-error-chip-bg: #f88078;
$color-error-chip-light: #fecdd2;

// LAYOUT
$grid-spacing: 20px;
$fixed-layout-height: 80px;
$fixed-layout-padding: 20px;
$authed-layout-padding: 40px;

$icon-size-sticky: 35px;
$icon-size: 35px;

// Light Theme Text
$dark-text: $black;
// $dark-primary-text: rgba($dark-text, 0.87);
// $dark-secondary-text: rgba($dark-text, 0.58);
$dark-primary-text: $black_87;
$dark-secondary-text: $black_58;

$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

// Font defaults
$claas-default-font-family: Roboto;
$claas-light-default-font-weight: 300 !important;
$claas-bold-default-font-weight: 500 !important;
