@use '@angular/material' as mat;
@import '/src/styles/typography.scss';
@import '/src/styles/variables.scss';

button.header-round-icon {
  line-height: 1;
}

app-machines-overview {
  azure-map-component {
    min-height: 320px !important;
    .map-container {
      width: 100%;
      height: inherit !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .sortDropdown .mat-mdc-select-arrow {
    color: $black_58;
  }
}

app-machine-filter {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    display: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-mdc-select-arrow {
    display: none;
  }

  .bigFilter {
    min-width: 120px;
  }

  .mat-mdc-select-value {
    max-width: 170px;
    width: auto;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field span.mat-form-field-label-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field span.mat-form-field-label-wrapper label.mat-empty {
    display: flex;
    justify-content: center;
    position: initial;
  }
  .filter-select {
    border: 2px solid transparent;
    &:focus {
      border-radius: 16px;
      border: 2px solid $color-claas-green;
    }
  }
  .filter-select .mat-mdc-select-trigger {
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

app-location-filter {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    display: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-mdc-select-arrow {
    display: none;
  }

  .bigFilter {
    min-width: 120px;
  }

  .mat-mdc-select-value {
    max-width: 170px;
    width: auto;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field span.mat-form-field-label-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field span.mat-form-field-label-wrapper label.mat-empty {
    display: flex;
    justify-content: center;
    position: initial;
  }
  .filter-select {
    border: 2px solid transparent;
    &:focus {
      border-radius: 16px;
      border: 2px solid $color-claas-green;
    }
  }
  .filter-select .mat-mdc-select-trigger {
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

app-cc-alarm-section {
  .filter-cancel-button {
    &.is-edge {
      .count-cancel-button-icon {
        margin-left: 0px !important;
      }
    }
  }
}

#service-tab {
  app-cc-alarm-section {
    .filter-cancel-button {
      &.is-chrome {
        .count-cancel-button-icon {
          margin-left: 0px !important;
        }
      }
      &.is-edge {
        .count-cancel-button-icon {
          margin-left: -0.5px !important;
        }
      }
    }
  }
}
#optimize-tab {
  app-machine-filter {
    .filter-cancel-button {
      &.is-edge {
        .count-cancel-button-icon {
          margin-left: 0px !important;
        }
      }
    }
  }
}

.data-recording-modal {
  mat-slide-toggle {
    label {
      cursor: pointer;
    }
  }
  .is-disabled {
    mat-slide-toggle {
      pointer-events: none;
      color: #000000;
      opacity: 0.3;
      button.mdc-switch {
        pointer-events: none;
        .mdc-switch__handle {
          pointer-events: none;
        }
      }
    }
  }
  app-data-recording-disabled {
    .cde-toast-title-text {
      color: #663d00;
    }
    .toast-warning-icon {
      vertical-align: text-bottom;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 16px solid #ff9800;
      font-size: 10px;
      &::before {
        content: '!';
        top: -2px;
        left: -1px;
      }
    }
  }
}

cde-sidenav {
  .is-expanded {
    .nav-list-item {
      width: 100%;
      height: 56px;
    }
  }
}

app-add-product-modal {
  .mat-step-header:hover:not([aria-disabled]) {
    background-color: transparent;
  }
  .mat-step-header:focus:not([aria-disabled]) {
    background-color: transparent;
  }
  .serialnumber {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      padding: 0;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    display: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-mdc-select-arrow {
    margin: 2px 15px 0 4px;
  }
}

.mat-progress-bar-buffer,
.mdc-linear-progress__buffer-bar {
  background-color: $color-light-gray !important;
}
.green-progressbar {
  .mdc-linear-progress__bar-inner {
    border-color: $color-success-main;
  }
}
.gray-progressbar {
  .mdc-linear-progress__bar-inner {
    border-color: $color-grey3;
  }
}
.red-progressbar {
  .mdc-linear-progress__bar-inner {
    border-color: $color-error-main;
  }
}
.orange-progressbar {
  .mdc-linear-progress__bar-inner {
    border-color: $color-warning-main;
  }
}
app-last-performed {
  app-mtnc-title-box {
    .mtnc-last-icon,
    .mtnc-title {
      color: transparent;
    }
  }
}

.is-orange {
  app-mtnc-title-box {
    .mtnc-last-icon,
    .mtnc-title {
      color: $color-warning-main;
    }
  }
  .operating-hour-strong {
    span {
      font-weight: $claas-bold-default-font-weight;
    }
  }
}
.is-green {
  app-mtnc-title-box {
    .mtnc-last-icon,
    .mtnc-title {
      color: $color-success-dark;
    }
  }
}

app-cc-contract,
app-cc-licence-contract,
app-cc-alarm {
  .max-content {
    text-align: center;
    .mat-sort-header-container {
      width: max-content;
    }
  }
  .lcTable {
    width: 100%;
  }

  .lcTable .mat-column-status {
    text-align: center;
    justify-content: center;

    div.mat-sort-header-container {
      text-align: center;
      justify-content: center !important;
    }
  }
}

app-dashboard-view {
  cde-map-component {
    min-height: 320px !important;
    .map-container {
      width: 100%;
      height: inherit !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .sortDropdown .mat-mdc-select-arrow {
    color: $black_58;
  }
}

app-dash {
  cde-map-component {
    min-height: 320px !important;
    .map-container {
      width: 100%;
      height: inherit !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .sortDropdown .mat-mdc-select-arrow {
    color: $black_58;
  }
}

// set width to a map of a bing web service
#map {
  canvas {
    width: 100% !important;
  }
}
.green-tick {
  color: $color-success-spare;
}

.modal-title-label {
  display: block;
  font-style: normal;
  font-weight: $claas-bold-default-font-weight;
  font-size: 20px;
  line-height: 32px;
  color: $black_87;
  margin-bottom: 16px;
}

body {
  &.configurations-layout {
    .overlay {
      min-height: calc(100% + 310px);
    }
    .cde-accordion-wrapper {
      cdk-accordion-item.is-open:last-child {
        margin-bottom: 26px;
      }
      cdk-accordion-item.is-closed:last-child {
        margin-bottom: 56px;
        z-index: 999;
        position: relative;
      }
    }
  }
  &.component-alerts-layout {
    box-sizing: border-box;
    .component-content {
      overflow-y: auto;
    }
  }
  &.component-webfonts-layout {
    box-sizing: border-box;
    .component-content {
      overflow-y: auto;
    }
  }
  &.component-modal-layout {
    box-sizing: border-box;
    .component-content {
      overflow-y: auto;
    }
  }
  &.component-range-pciker-layout {
    box-sizing: border-box;
    .component-content {
      overflow-y: auto;
    }
  }
}

.machines {
  .machine-tile {
    .tile-measurement-details {
      min-width: 76px;
      max-width: 105px;
      padding-right: 5px;
      overflow: hidden;
    }
    .tile-measurement-component {
      &:last-child {
        .tile-measurement-details {
          width: 102px;
          margin-right: 0px;
        }
      }
    }
  }
}

.details-tile-header {
  .tile-measurement-details {
    width: auto;
  }
}

.details-dealer-header {
  .details-dealer-title {
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 8px 0;
  }
  .details-dealer-subtitle {
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 24px 0;
  }
}
.details-dealer-content {
  display: flex;
  align-items: stretch;
  padding-top: 0 !important;

  .dealer-view {
    border: 1px solid rgba(0, 0, 0, 0.12);

    .details-dealer-title {
      margin: 0 0 8px 0;
    }
    .details-dealer-subtitle {
      margin: 0 0 24px 0;
    }
    .preferred-dealer-card {
      .preferred-dealer-img {
        height: 100%;
        height: 100%;
      }
    }
    .preferred-dealer-description-container {
      .subtitle-2 {
        font-size: 14px !important;
      }
    }
  }
}

.add-new-item {
  width: 480px;
  text-align: center;
  .add-new-item-icon {
    width: 360px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    img {
      display: inline-block;
    }
  }
  .add-first-item-icon {
    width: 185px;
    height: 177px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    img {
      display: inline-block;
    }
  }
  .add-new-item-desc {
    .add-new-item-desc-title {
      font-style: normal;
      font-size: 24px;
      line-height: 32px;
      color: $black_87;
      margin-bottom: 8px;
      text-transform: none;
    }
    .add-new-item-desc-text {
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      color: $black_58;
      margin-bottom: 40px;
      span {
        display: block;
      }
    }
  }
}

.no-map {
  width: 100%;
  text-align: center;
  .no-map-icon {
    width: 360px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    img {
      display: inline-block;
    }
  }
  .no-map-title {
    display: block;
    text-align: center;
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    color: $black_87;
    text-transform: none;
  }
}
// TODO: move this to section-not-available.component.scss
.section-not-available {
  width: 480px;
  margin: 45px auto 40px;
  display: flex;
  align-items: center;
  .section-not-available-icon {
    margin-right: 24px;
    img {
      display: inline-block;
    }
  }
  .section-not-available-desc {
    text-align: center;
    margin-top: 40px;
    .section-not-available-title {
      display: block;
      font-style: normal;
      font-size: 24px;
      line-height: 32px;
      color: $black_87;
      margin-bottom: 8px;
      .not-available-message,
      .not-available {
        display: block;
        white-space: nowrap;
      }
    }
    .section-not-available-subtitle {
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      color: $black_58;
      margin-bottom: 40px;
      span {
        display: block;
      }
    }
  }
}

code {
  background-color: $color-gray-f7;
  color: $black;
  border-radius: 5px;
  font-size: 85%;
  padding: 0.1em 0.2em;
  display: inline-block;
}

.is-disabled {
  pointer-events: none;
  opacity: 0.95;
}

.no-pointer-events {
  pointer-events: none;
}

.archive-banner {
  .cde-toast-title-text {
    color: $color-information;
  }

  .cde-inline-toast-message {
    color: $black_87;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }
}

.warning-banner {
  color: #804c00;

  .cde-toast-title-text {
    font-weight: $claas-bold-default-font-weight;
  }

  .cde-inline-toast-icon {
    color: #C77700;
  }

  .cde-inline-toast-message {
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }
}

$info-text-color: rgba(0, 54, 84, 1);

.info-banner {
  color: $info-text-color;;

  .cde-inline-toast-title {
    color: $info-text-color;
    position: relative;
    bottom: 2px;
  }

  .cde-toast-title-text {
    font-weight: $claas-bold-default-font-weight;
  }

  .cde-inline-toast-icon {
    color: $color-pending-state;
    margin-right: 16px;
  }

  .cde-inline-toast-details {
    display: flex;
    justify-content: start;
    align-items: start;
  }

  .cde-inline-toast-message {
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }

  button.cde-toast-button {
    color: $black_87;

    lib-icon.close-icon {
      svg path {
        fill: $black_87 !important;
      }
    }
  }

  .dispatch-link {
    text-decoration: underline;
  }
}

.cde-section-page {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .create-org-section {
    padding: 24px;
    height: -webkit-fill-available;
    display: flex;
    align-self: stretch;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .create-org-section-placeholder {
    height: inherit;
    width: 100%;
  }
}

.reg-org-btn {
  display: flex;
  align-items: center;
  border: 1px solid $color-button-green;
  width: 100%;
  height: 36px;
  border-radius: 4px;
  justify-content: center;
  margin-top: 16px;
  background-color: #f5f5f5;
  outline: none;
  cursor: pointer;
  .link-text {
    font-weight: $claas-bold-default-font-weight;
    font-size: 14px;
    color: $black_87;
    line-height: 24px;
  }
}

@media only screen and (max-width: 1700px) {
  body {
    &.configurations-layout {
      .overlay {
        min-height: calc(100% + 340px);
      }
    }
  }
}

@media only screen and (max-width: 1441px) {
  body {
    &.configurations-layout {
      .overlay {
        min-height: calc(100% + 350px);
      }
    }
  }
}

@media only screen and (min-width: 1420px) and (max-width: 2000px) {
  .configs-cards-list {
    li {
      &.three-items,
      &.one-item,
      &.two-items {
        .configs-card-footer {
          position: absolute;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: flex-start;
          .configs-card-btn {
            &:first-child {
              margin-bottom: 0;
            }
            &:last-child {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

.f5-background {
  background-color: $color-gray-f5 !important;
}

.no-data {
  .add-new-item {
    margin: auto;
  }
}
.loading-data {
  min-width: 360px;
  min-height: 200px;
  padding-top: 20px;
  font-size: 12px;
}

.show-more-box {
  margin-top: 30px;
  margin-bottom: 30px;
  .show-more-btn {
    background-color: $color-white;
    padding: 9px 15px;
    cursor: pointer;
    border: 1px solid $color-claas-green;
    border-radius: 4px;
    text-align: center;
    .show-more-icon {
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 10px;
      font-size: 18px;
      line-height: 1;
      img {
        width: 100%;
        display: block;
      }
    }
    .show-more-text {
      display: inline-block;
      vertical-align: middle;
      margin-left: 12px;
      font-style: normal;
      font-weight: $claas-bold-default-font-weight;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.4px;
      color: $black_87;
    }
    &:hover {
      background-color: $color-claas-green-hover2;
    }
  }
}
.weather-widget-modal,
.bzawidget-search {
  input {
    line-height: 16px;
  }
}

.demo-widget {
  .widget-card-title,
  .title-head-text {
    color: $color-pending-state;
  }
  .machine-info {
    color: rgba(0, 0, 0, 0.87);
  }
  .dealer-img {
    filter: grayscale(1);
  }
  .machine-img {
    filter: grayscale(1);
  }
  .dealer-img {
    filter: grayscale(1);
  }
}

.card-bodyMob {
  .quickinfocontent {
    min-width: 40px;
  }
  .quickinfodate {
    font-family: $claas-default-font-family;
    font-style: normal;
    font-size: 10px;
    height: 18px;
    color: grey;
  }
  .quickinfocloudMo {
    font-size: 18px;
    color: grey;
    margin-left: 8px;
    height: 18px;
  }
  .quickinfocloudDi {
    font-size: 18px;
    color: grey;
    margin-left: 8px;
    height: 18px;
  }
  .quickinfocloudMi {
    font-size: 18px;
    color: grey;
    margin-left: 8px;
    height: 18px;
  }
  .quickinfocloudDo {
    font-size: 18px;
    color: grey;
    margin-left: 8px;
    height: 18px;
  }
  .quickinfotemp {
    font-family: $claas-default-font-family;
    font-style: normal;
    font-size: 12px;
    height: 12px;
  }
}

app-weatherwidget .mat-mdc-card-title {
  padding: 16px 16px 0px 16px !important;
}

@media screen and (max-width: 1248px) {
  body {
    &.configurations-layout {
      .overlay {
        min-height: calc(100% + 380px);
      }
    }
  }
  .archive-banner,
  .licenses-banner {
    .cde-inline-toast-container {
      height: auto;
    }
  }
}

@media screen and (max-width: 1500px) {
  app-bzawidget {
    .dashboardpie {
      right: unset !important;
      top: unset !important;
    }
  }
}

@media screen and (max-width: 992px) {
  body {
    &.configurations-layout {
      .overlay {
        height: 100vh;
        min-height: 100%;
      }
    }
  }
}

@media screen and (max-width: 959px) {
  app-cc-contract,
  app-cc-licence-contract,
  app-cc-alarm {
    .lcTable .mat-column-status {
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 640px) {
  .add-new-item {
    width: 100%;
    .add-new-item-icon {
      width: 185px;
      height: 165px;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    .add-new-item-desc {
      .add-new-item-desc-title {
        font-size: 20px;
        line-height: 26px;
        text-transform: none;
      }
      .add-new-item-desc-text {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
