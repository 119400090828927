// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat.core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $cde-fe-app-primary: mat.define-palette(mat.$indigo-palette);
// $cde-fe-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $cde-fe-app-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $cde-fe-app-theme: mat.define-light-theme((
//   color: (
//     primary: $cde-fe-app-primary,
//     accent: $cde-fe-app-accent,
//     warn: $cde-fe-app-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include mat.all-component-themes($cde-fe-app-theme);

@import "./styles/variables";

html,
body {
  height: 100%;
  width: 100%;
  position: relative;
}
body {
  margin: 0;
  position: relative;
  font-family: $claas-default-font-family;
}

footer {
  // height: 44px;
  padding-top: 8px;
  text-align: right;
}

// @tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/base";
@import "./styles/cde-theme.scss";

@import "./styles/component-selector-styles";
@import "./styles/material-overrides";
@import "./styles/button-styles";
@import "./styles/filter-button-styles";
@import "./styles/datepicker";
@import "./styles/forms";
@import "./styles/toast";
@import "./styles/usercentrics";
// this can be removed when the validation dialog styling is fixed within the
// registration dialog
@import "./styles/address-validation-dialog";
@import "./styles/card";
