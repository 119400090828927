@use "@angular/material" as mat;
@import "/src/styles/typography.scss";
@import "/src/styles/variables.scss";

.filter-button {
  position: relative;
  background-color: $color-background !important;
  display: inline-flex;
  height: 32px !important;
  border-radius: 16px !important;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid $color-light-gray;
  width: auto !important;
  box-sizing: border-box;
  .mat-mdc-select-trigger {
    padding: 5px 13.5px;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.mat-mdc-outlined-button {
    display: inline-block;
    padding: 5px 13.5px;
    line-height: 1 !important;
  }
  &.cde-stroked-button {
    padding: 5px 13.5px;
    line-height: 1 !important;
    .cde-stroked-button-text {
      display: inline-block;
      &::before {
        display: block;
        content: attr(title);
        font-weight: $claas-bold-default-font-weight;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }
  &.with-cancel-button {
    // padding: 5px 8px 5px 14px;
    .filter-cancel-button {
      margin-right: 8px;
    }
  }
  &.is-open,
  &.is-active,
  &.is-selected,
  &.is-active.is-selected,
  &:active {
    outline-offset: -2px;

    @supports (not(-webkit-hyphens:none)) {
      outline: 2px solid $dark-secondary-text;
    }

    @supports (-webkit-hyphens:none) {
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        border: 2px solid $dark-secondary-text;
        border-radius: 16px;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-value-text,
    .mat-mdc-select-placeholder {
      font-weight: $claas-bold-default-font-weight;
      font-size: 14px;
      line-height: 20px;
    }
    .mat-mdc-select-placeholder:first-letter {
      text-transform: capitalize !important;
    }
    .manual-menu-text {
      font-weight: $claas-bold-default-font-weight;
    }
    .cde-stroked-button-text {
      font-weight: $claas-bold-default-font-weight;
      letter-spacing: 0px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
      font-weight: $claas-bold-default-font-weight;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.025em;
    }
    .filter-select {
      border: 2px solid transparent;
      &:focus {
        border: 2px solid transparent;
      }
    }
  }
  &.cde-mat-button {
    &.is-active,
    &:focus,
    &:active {
      @supports (not(-webkit-hyphens:none)) {
        outline: 2px solid $dark-secondary-text;
      }

      @supports (-webkit-hyphens:none) {
        &::before {
          content: "";
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          border: 2px solid $dark-secondary-text;
          border-radius: 16px;
        }
      }
    }
  }
  &.is-active {
    @supports (not(-webkit-hyphens:none)) {
      outline: 2px solid $dark-secondary-text;
    }

    @supports (-webkit-hyphens:none) {
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        border: 2px solid $dark-secondary-text;
        border-radius: 16px;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-value-text,
    .mat-mdc-select-placeholder {
      display: inline-block;
      margin-top: 5px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-value-text {
      max-width: 163px;
    }
  }
  &:focus {
    border: 1px solid $color-claas-green;
  }
  &:hover {
    border: 1px solid #616161;
    background-color: $color-background;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  mat-select {
    height: 32px;
  }
}
