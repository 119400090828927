@use '@angular/material' as mat;
@import '/src/styles/typography.scss';
@import '/src/styles/variables.scss';

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}
.is-machine-display-input {
  margin-bottom: 43px;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}
.is-machine-implements-input {
  margin-bottom: 43px;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.cde-input {
  font-style: normal !important;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  ~ .mat-form-field-label-wrapper {
    font-style: normal;
  }
  &::-webkit-input-placeholder {
    color: $black_58;
  }
  &:-moz-placeholder {
    color: $black_58;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $black_58;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $black_58;
  }
  &::-ms-input-placeholder {
    color: $black_58;
  }
  &::placeholder {
    color: $black_58;
  }
}

.cde-material-select {
  .mat-mdc-form-field-infix {
    border-top: 10px solid transparent;
  }
  &.mat-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      padding: 5px 0 16px 0px;
    }
    .mat-mdc-form-field-infix {
      padding-top: 12px;
    }
    .mat-mdc-select-arrow-wrapper {
      transform: translateX(35%);
      transform: translateY(-13%);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-outline-thick {
      color: $color-claas-green;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-label-wrapper {
    font-size: 14px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-label {
    margin-top: 0 !important;
  }
}

.cde-textarea {
  height: 88px;
  resize: none;
  border: 1px solid $black_12;
  border-radius: 4px;
  box-sizing: border-box !important;
  outline: none;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.15px;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  ~ .mat-form-field-label-wrapper {
    font-style: normal;
    font-size: 14px;
  }
  &::-webkit-input-placeholder {
    color: $black_58;
  }
  &:-moz-placeholder {
    color: $black_58;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $black_58;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $black_58;
  }
  &::-ms-input-placeholder {
    color: $black_58;
  }
  &::placeholder {
    color: $black_58;
  }
}
.cde-textarea-box {
  .cde-error-message.matError {
    position: relative;
    top: 67px;
    left: 70px;
  }
  textarea {
    resize: none !important;
  }
}

.cde-select-box {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field-flex {
    &:focus,
    &:active,
    &:focus-visible,
    &:hover {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-appearance-outline .mat-form-field-outline-thick {
        color: $color-claas-green;
      }
    }
  }
  .mat-form-field-appearance-outline {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper {
      margin: 0 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-outline {
      position: absolute;
      top: 0;
    }
    .mat-mdc-select-arrow-wrapper {
      transform: translate(10px, -10%);
    }
    .mat-mdc-form-field-infix {
      padding: 0;
      margin-top: -4px;
      padding-top: 2px;
      padding-bottom: 11px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-label {
      top: 20px;
      margin-top: 0;
      font-size: 14px;
      &.mat-empty {
        top: 13px;
        &.mat-form-field-empty {
          top: 17px;
        }
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-label-wrapper {
    overflow: visible;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
    top: 6px;
    margin-top: 8px;
    &.mat-empty {
      top: 10px;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-value-text {
    font-size: 14px;
  }
}

.cde-material-form,
.cde-textarea-box,
.cde-select-box {
  .mat-mdc-form-field-subscript-wrapper {
    overflow: visible !important;
    padding: 0 !important;
    height: auto;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field.mat-focused.mat-form-field-invalid .mat-form-field-label {
    color: $color-error-main !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
    color: $color-error-main !important ;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: $color-error-main !important;
  }
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.generic-form,
.cde-material-form {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  mat-select {
    font-size: 16px;
  }
  .country-phone-input {
    font-size: 16px !important;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

textarea.cde-material-textarea {
  display: block;
  width: 100%;
  height: 108px;
  resize: none;
  border: 1px solid $black_12;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-family: $claas-default-font-family;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.15px;
  padding: 14px 8px;
  &::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
  } /* Chrome <=56, Safari < 10 */
  &:-moz-placeholder {
    opacity: 1;
    -moz-transition: opacity 0.5s;
    transition: opacity 0.5s;
  } /* FF 4-18 */
  &::-moz-placeholder {
    opacity: 1;
    -moz-transition: opacity 0.5s;
    transition: opacity 0.5s;
  } /* FF 19-51 */
  &:-ms-input-placeholder {
    opacity: 1;
    -ms-transition: opacity 0.5s;
    transition: opacity 0.5s;
  } /* IE 10+ */
  &::placeholder {
    opacity: 1;
    transition: opacity 0.5s;
  } /* Modern Browsers */
  &:not(:placeholder-shown) {
    & ~ .floating-label {
      color: $black_58;
      display: inline-block;
      left: 10px;
      opacity: 1;
    }
  }
  &:placeholder-shown {
    & ~ .floating-label {
      display: none;
      left: 10px;
      opacity: 1;
    }
  }
  &:focus,
  &:visited,
  &:focus-visible,
  &:active {
    outline: none;
    border: 2px solid $color-claas-green;
    &::-webkit-input-placeholder {
      opacity: 0;
    } /* Chrome <=56, Safari < 10 */
    &:-moz-placeholder {
      opacity: 0;
    } /* FF 4-18 */
    &::-moz-placeholder {
      opacity: 0;
    } /* FF 19-50 */
    &:-ms-input-placeholder {
      opacity: 0;
    } /* IE 10+ */
    &::placeholder {
      opacity: 0;
    } /* Modern Browsers */
    & ~ .floating-label {
      display: inline-block;
      left: 10px;
      opacity: 1;
    }
  }
}

.cde-form {
  .mat-mdc-form-field-subscript-wrapper {
    padding: 0 !important;
  }

  mat-error.mat-mdc-form-field-error {
    color: $color-error-main;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: $color-error-main !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
    color: $color-error-main !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field.mat-focused.mat-form-field-invalid .mat-form-field-label {
    color: $color-error-main !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: $color-error-main !important;
  }
}

.cde-error-message {
  color: $color-error-main !important;
  font-size: 12px !important;
  line-height: 20px;
  width: max-content;
  display: inline-flex;
  &.default {
    left: 0;
    top: 42px;
  }
  &.t-23 {
    top: 23px;
  }
  &.t-44 {
    top: 44px;
  }
  &.t-53 {
    top: 53px;
  }
  &.matError {
    position: relative;
    top: 1px;
    &.t--1 {
      top: -3px;
    }
    &.t--3 {
      top: -3px;
    }
    &.t--5 {
      top: -5px;
    }
    &.t--16 {
      top: -16px;
    }
    &.l--12 {
      left: -12px;
    }
  }
}

.serial-custom-error {
  .cde-error-message {
    width: 305px;
  }
}

.has-textarea-error,
.has-error {
  .cde-material-input {
    border: 1px solid $color-error-main !important;
    &:active,
    &:focus-visible,
    &:focus {
      outline: none;
      border: 2px solid $color-error-main;
      & ~ .floating-label {
        color: $color-error-main;
        display: inline-block;
        left: 10px;
        opacity: 1;
      }
    }
  }
  .floating-label {
    color: $color-error-main !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $color-error-main !important;
    opacity: 1 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-label {
    color: $color-error-main !important;
  }
}

.mat-mdc-form-field {
  ngx-mat-intl-tel-input {
    .ngx-mat-tel-input-container {
      button.country-selector {
        height: 100%;
        width: 87px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 8px;
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-wrapper {
          width: 90px;
          display: flex;
          align-items: flex-start;
          position: relative;
          bottom: 2px;
          .country-selector-flag {
            margin-right: 8px;
          }
          .country-selector-code {
            width: 25px;
          }
        }
      }
      input {
        padding: 1px 6px 0 90px;
      }
    }
  }
}

#personalDataForm .mat-mdc-form-field ngx-mat-intl-tel-input .ngx-mat-tel-input-container button.country-selector {
  background-position: 67px 8px;
  text-align: left;
  .country-selector-code {
    vertical-align: top;
    line-height: 16px;
    font-size: 16px;
  }
}

// .mat-mdc-menu-panel.mat-mdc-menu-panel {
//   max-width: 330px !important;
//   background: #ffffff;
// }

// ensure correct font size for floating placeholder labels in forms
.mdc-text-field--outlined .mdc-floating-label mat-label {
  font-size: 16px;
}
